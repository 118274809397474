<template>
  <div class="ml-4">
    <div class="flex items-start">
      <p class="text-xl font-bold text-center text-black">
        My subscriptions
      </p>
    </div>
    <div class="w-72 text-center bg-white rounded-2xl mt-3 p-5">
      <h1 class="text-3xl font-extrabold text-green">$29</h1>
      <p class="text-xs leading-none text-gray-500 mt-2">per month</p>

      <p class="py-4 text-xs leading-4 text-black text-center">
        Your subscription is active since:<br />
        <b>{{ subscriptionStartDate }}</b>
      </p>
      <t-button danger class="mx-auto mt-2">CANCEL SUBSCRIPTION</t-button>
    </div>
  </div>
</template>

<script>
import TButton from "@/components/core/Button.vue";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "CancelSub",
  components: {
    TButton,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    subscriptionStartDate() {
      if (this.currentUser.subscription) {
        return DateTime.fromISO(this.currentUser.subscription.created).toFormat(
          "dd.MM.yyyy"
        );
      }
    },
  },
};
</script>
