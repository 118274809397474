<template>
  <card class="">
    <template #title>Subscribe</template>
    <template>
      <div class="flex flex-col items-center">
        <h1 class="text-3xl font-extrabold text-green">$49</h1>
        <p class="text-xs leading-none text-gray-500 pt-1">per month</p>

        <p class="py-4 text-xs leading-4 text-black text-center">
          Get unlimited usage with our a simple easy pricing model, billed
          monthly
        </p>
        <t-button primary class="mx-10 mt-6" @click="trySubscribe"
          >Checkout with Stripe</t-button
        >
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";
import TButton from "@/components/core/Button.vue";

import { mapGetters } from "vuex";

import axios from "axios";

export default {
  name: "Subscribe",
  components: {
    Card,
    TButton,
  },
  methods: {
    async trySubscribe() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}/api-rest/v1/payments/plans/1/order/`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          window.location.href = response.data.stripe_session_url;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
