<template>
  <d-layout class="mt-20">
    <template #controls>
      <div
        class="w-full bg-white flex flex-col gap-2 rounded-2xl mt-9 py-2 px-8"
      >
        <router-link class="text-base font-bold" to="/dashboard">
          Timers
        </router-link>
        <router-link class="text-base font-bold" to="/account">
          Account
        </router-link>
      </div>
    </template>
    <template #content>
      <div class="ml-4 flex flex-row">
        <subscribe v-if="!currentUser.subscription" />
        <cancel-sub v-else />
      </div>
    </template>
  </d-layout>
</template>

<script>
import DLayout from "../layouts/DLayout.vue";
import TButton from "@/components/core/Button.vue";

import Subscribe from "./subs/Subscribe.vue";
import CancelSub from "./subs/Cancel.vue";

import { mapGetters } from "vuex";

export default {
  name: "Account",
  components: {
    DLayout,
    TButton,
    Subscribe,
    CancelSub,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
